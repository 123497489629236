import { useMemo, useState, type Dispatch, type FC, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, toast } from '@volvo/vce-uikit';
import { DropdownSearch } from '../../../../../components/common/dropdown-search/DropdownSearch';
import { CenteredSpinner } from '../../../../../components/common/loading/CenteredSpinner';
import {
  useSiteMaterialVariants,
  useUpdateExternalMaterialMapping,
  type FragmentExternalMaterialMapping,
} from '../../../../../gql-types/generated-types-super-graph';
import { Row } from './row/Row';
import { DrawerContentContainer, MaterialVariant } from './styles';

type TableProps = {
  companyId: string;
  selected: FragmentExternalMaterialMapping | null;
  setSelected: Dispatch<SetStateAction<FragmentExternalMaterialMapping | null>>;
  onUpdate: () => void;
};

export const EditDrawer: FC<TableProps> = ({ selected, setSelected, companyId, onUpdate }) => {
  const { t } = useTranslation();

  const { data, loading } = useSiteMaterialVariants({ variables: { companyId } });
  const [update] = useUpdateExternalMaterialMapping({
    onCompleted: () => {
      toast.success(t('deviations.material-mapping.drawer.success'));
      onUpdate();
      onClose();
    },
  });

  const [dirty, setDirty] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<{ id: string; name: string } | null>(
    selected?.materialVariant ?? null,
  );

  const materialVariants = useMemo(
    () =>
      data?.siteCompanies.company?.materialFamilies
        .map((f) =>
          f.materialTypes
            .map((t) =>
              t.materialVariants.map((v) => ({
                id: v.id,
                value: `${v.name}, ${t.name}, ${f.name}`,
              })),
            )
            .flat(),
        )
        .flat(),
    [data?.siteCompanies.company?.materialFamilies],
  );

  const submit = () => {
    if (!selected) {
      return;
    }
    update({
      variables: { id: selected.id, input: { materialVariantId: selectedMaterial?.id ?? null } },
    });
  };

  const onClose = () => {
    setDirty(false);
    setSelectedMaterial(null);
    setSelected(null);
  };

  return (
    <Drawer open={Boolean(selected)}>
      <DrawerHeader
        heading={t('deviations.material-mapping.drawer.header')}
        onClose={onClose}
        isBorderPresent
      />
      <DrawerContent>
        <DrawerContentContainer>
          <Row
            title={t('deviations.material-mapping.external-name')}
            content={selected?.externalName}
          />
          <Row
            title={t('deviations.material-mapping.source')}
            content={t(selected?.source ?? '-')}
          />
          {loading ? (
            <CenteredSpinner />
          ) : (
            <Row title={t('deviations.material-mapping.material-name')}>
              <DropdownSearch
                items={materialVariants ?? []}
                onSelect={(item) => {
                  setDirty(true);
                  setSelectedMaterial(item ? { id: item.id, name: item.value } : null);
                }}
                renderItem={(variant) => <MaterialVariant>{variant.value}</MaterialVariant>}
                placeholder={t('deviations.material-mapping.drawer.search-material')}
              />
            </Row>
          )}
        </DrawerContentContainer>
      </DrawerContent>
      <DrawerFooter
        isBorderPresent
        style={{
          display: 'flex',
          gridGap: 8,
        }}
      >
        <Button fullWidth variant="secondary" disabled={loading} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button fullWidth variant="primary" disabled={loading || !dirty} onClick={submit}>
          {t('Save')}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
