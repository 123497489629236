import styled from '@emotion/styled';

export const DrawerContentContainer = styled.section`
  display: grid;
  row-gap: 24px;
`;

export const MaterialVariant = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.map.layout.padding} 0;
`;
