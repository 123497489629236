import { type FC, type PropsWithChildren } from 'react';
import { Typography } from '@volvo/vce-uikit';
import { Container } from './styles';

interface Props extends PropsWithChildren {
  title?: string;
  content?: string;
}

export const Row: FC<Props> = ({ children, content, title = '' }) => (
  <Container>
    <Typography variant="caption1">{title}</Typography>
    {content && (
      <Typography variant="subtitle1" color="textSubtle">
        {content}
      </Typography>
    )}
    {children}
  </Container>
);
