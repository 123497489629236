import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterContextProvider,
  NotificationBadge,
  PageHeader,
  useNotificationBadgeContext,
} from '@volvo/vce-package-site-uikit';
import { useExternalMaterialMappings } from '../../../gql-types/generated-types-super-graph';
import { MaterialMapping } from '../material-mapping/MaterialMapping';
import { UnassignedLoadTickets } from '../unassigned-load-tickets/UnassignedLoadTickets';
import { Container } from './styles';

export type Props = { siteId: string; companyId: string; pageSize: number };

const tabKeys = ['UNASSIGNED_LOAD_TICKETS', 'MATERIAL_MAPPING'];

export const Content = ({ siteId, companyId, pageSize }: Props) => {
  const { t } = useTranslation();
  const { getNotificationBadge, setNotificationBadge } = useNotificationBadgeContext();
  const [openTab, setOpenTab] = useState<(typeof tabKeys)[number]>(
    localStorage.getItem('deviations_tab') ?? tabKeys[0],
  );

  useEffect(() => {
    localStorage.setItem('deviations_tab', openTab.toString());
  }, [openTab]);

  const materialMappingsResult = useExternalMaterialMappings({
    variables: { companyId },
  });

  useEffect(() => {
    if (!materialMappingsResult.loading && materialMappingsResult.data) {
      setNotificationBadge({
        id: 'material-mapping',
        content:
          materialMappingsResult.data.siteCompanies.company?.externalMaterialMappings.edges.filter(
            ({ node }) => !node.materialVariant,
          ).length ?? 0,
      });
    }
  }, [setNotificationBadge, materialMappingsResult.loading, materialMappingsResult.data]);

  const notificationBadge = getNotificationBadge('material-mapping');

  return (
    <Container>
      <PageHeader
        title={t('load-tickets.page-title')}
        tabs={tabKeys.map((key) => ({
          key,
          children: key === 'MATERIAL_MAPPING' && notificationBadge && (
            <>
              {t(`deviations.tabs.${key}`)}
              <NotificationBadge badge={notificationBadge} />
            </>
          ),
          title: t(`deviations.tabs.${key}`),
          onClick: () => setOpenTab(key),
          active: key === openTab,
        }))}
      />
      {openTab === 'UNASSIGNED_LOAD_TICKETS' ? (
        <FilterContextProvider
          defaultStartDate={dayjs().subtract(7, 'day')}
          defaultEndDate={dayjs()}
        >
          <UnassignedLoadTickets siteId={siteId} companyId={companyId} pageSize={pageSize} />
        </FilterContextProvider>
      ) : openTab === 'MATERIAL_MAPPING' ? (
        <MaterialMapping result={materialMappingsResult} companyId={companyId} />
      ) : null}
    </Container>
  );
};
