import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, TagType, Table as UiKitTable, type OnRowClick } from '@volvo/vce-uikit';
import { type FragmentExternalMaterialMapping } from '../../../../gql-types/generated-types-super-graph';
import { EditDrawer } from './edit-drawer/EditDrawer';
import { Container } from './styles';

type TableProps = {
  data: FragmentExternalMaterialMapping[];
  onUpdate: () => void;
  companyId: string;
};

export const Table: FC<TableProps> = ({ data, companyId, onUpdate }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<FragmentExternalMaterialMapping | null>(null);

  return (
    <Container>
      <UiKitTable
        fullWidth
        data={data}
        onRowClick={(e: OnRowClick<FragmentExternalMaterialMapping>['arguments']) => {
          setSelected(e.row.original);
        }}
        columns={[
          {
            header: t('deviations.material-mapping.external-name'),
            accessorKey: 'externalName',
          },
          {
            header: t('deviations.material-mapping.material-name'),
            accessorKey: 'materialVariant.name',
            cell: (props: { getValue: () => string | undefined }) =>
              props.getValue() ?? (
                <Tag type={TagType.WarningSubtle}>{t('deviations.needs-mapping')}</Tag>
              ),
          },
          {
            header: t('deviations.material-mapping.source'),
            accessorKey: 'source',
            cell: (props: { getValue: () => string | undefined }) => t(props.getValue() ?? '-'),
          },
        ]}
      />
      <EditDrawer
        selected={selected}
        setSelected={setSelected}
        companyId={companyId}
        onUpdate={onUpdate}
      />
    </Container>
  );
};
