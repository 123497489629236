import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonVariant,
  InlineMessage,
  InlineMessageActions,
  Typography,
} from '@volvo/vce-uikit';

export const GoToFlows: FC<{ siteId: string; companyId: string }> = ({ siteId, companyId }) => {
  const { t } = useTranslation();

  return (
    <InlineMessage icon="info" variant="info">
      <Typography variant="caption1">{t('load-tickets.go-to-flows-info')}</Typography>
      <InlineMessageActions>
        <Button
          variant={ButtonVariant.Secondary}
          iconEnd="chevron-right"
          onClick={() => {
            window.location.assign(`/companies/${companyId}/sites/${siteId}/map/flow/`);
          }}
        >
          {t('load-tickets.go-to-flows-button-text')}
        </Button>
      </InlineMessageActions>
    </InlineMessage>
  );
};
