import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 0 0 12px;
  background-color: ${({ theme }) => theme.colors.bgBase};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderSubtle};
`;
