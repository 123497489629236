import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import { useMemo, type FC } from 'react';
import { I18nextProvider } from 'react-i18next';
import { NotificationBadgeProvider } from '@volvo/vce-package-site-uikit';
import { useAuth } from '@volvo/vce-uikit';
import { getApolloClient } from '../../gql/apollo-client';
import mapI18n from '../../i18n';
import { theme } from '../../theme';
import { Content } from './content/Content';

export type Props = { siteId: string; companyId: string; pageSize?: number };

export const Deviations: FC<Props> = ({ siteId, companyId, pageSize = 30 }) => {
  const { getToken } = useAuth();
  const client = useMemo(() => getApolloClient(getToken), [getToken]);

  return (
    <I18nextProvider i18n={mapI18n}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <NotificationBadgeProvider>
            <Content
              siteId={siteId}
              companyId={companyId}
              pageSize={pageSize < 100 ? pageSize : 100}
            />
          </NotificationBadgeProvider>
        </ThemeProvider>
      </ApolloProvider>
    </I18nextProvider>
  );
};
