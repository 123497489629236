import dayjs from 'dayjs';
import { type Dispatch, type FC, type PropsWithChildren, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Loadrite, Tamtron, Volvo } from '@volvo/vce-package-site-uikit';
import { Tag, TagType, Table as UiKitTable } from '@volvo/vce-uikit';
import { ExternalReferenceTypes } from '../../../../gql-types/generated-types-super-graph';
import { getSystemType } from '../getTypes';
import type { SortingState, SourceFlow } from '../types';
import { IconWithLabel } from './styles';

type TableProps = {
  loadTickets: SourceFlow[];
  sortingState: SortingState;
  setSortingState: Dispatch<SetStateAction<SortingState>>;
};

export const Table: FC<PropsWithChildren<TableProps>> = ({
  loadTickets,
  sortingState,
  setSortingState,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <UiKitTable
      fullWidth
      enableSorting
      manualSorting
      onStateChange={setSortingState}
      data={loadTickets}
      state={sortingState}
      columns={[
        {
          header: t('load-tickets.unmapped-table.columns.source-flow-id'),
          accessorKey: 'sourceTicketFlowId',
        },
        {
          header: t('load-tickets.unmapped-table.columns.flow-name'),
          accessorKey: 'connectedFlowName',
          enableSorting: false,
          cell: (props: any) =>
            props.getValue() ?? (
              <Tag type={TagType.WarningSubtle}>{t('deviations.needs-mapping')}</Tag>
            ),
        },
        {
          header: t('load-tickets.unmapped-table.columns.number-of-unmapped-tickets'),
          accessorKey: 'count',
        },
        {
          header: t('load-tickets.unmapped-table.columns.system-type'),
          accessorKey: 'systemType',
          enableSorting: false,
          cell: (props: any) => {
            const value = props.getValue();
            return getSystemType(value, t);
          },
        },
        {
          header: t('load-tickets.unmapped-table.columns.ingestion-provider'),
          accessorKey: 'ingestionService',
          enableSorting: false,
          cell: (props: any) => {
            const value = props.getValue();
            switch (value) {
              case ExternalReferenceTypes.Tamtron:
                return (
                  <IconWithLabel>
                    <Tamtron />
                    {t('load-tickets.unmapped-table.external-sources.tamtron')}
                  </IconWithLabel>
                );
              case ExternalReferenceTypes.Flintab:
                return t('load-tickets.unmapped-table.external-sources.flintab');
              case ExternalReferenceTypes.Loadrite:
                return (
                  <IconWithLabel>
                    <Loadrite /> {t('load-tickets.unmapped-table.external-sources.loadrite')}
                  </IconWithLabel>
                );
              case ExternalReferenceTypes.VolvoAssist:
                return (
                  <IconWithLabel>
                    <Volvo />
                    {t('load-tickets.unmapped-table.external-sources.volvo')}
                  </IconWithLabel>
                );
              case ExternalReferenceTypes.OperatorApp:
                return t('load-tickets.unmapped-table.external-sources.operator-app');
              default:
                return t('load-tickets.unmapped-table.external-sources.other');
            }
          },
        },
        {
          header: t('load-tickets.unmapped-table.columns.last-triggered'),
          accessorKey: 'lastTicket',
          enableSorting: false,
          cell: (props: any) => {
            const value = props.getValue();
            return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '';
          },
        },
      ]}
    >
      {children}
    </UiKitTable>
  );
};
