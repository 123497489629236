import styled from '@emotion/styled';
import { Tabs } from '@volvo/vce-uikit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.bgBase};
`;

export const StyledTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.colors.bg};
  padding-left: 24px;
`;
