import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type FC,
  type KeyboardEventHandler,
} from 'react';
import { SearchField } from '@volvo/vce-uikit';
import { SearchResult, SearchResultWrapper } from './styles';

type Item = { id: string; value: string };

type Props = {
  items: Item[];
  renderItem: (item: Item) => JSX.Element;
  onSelect: (item: Item | null) => void;
  placeholder: string;
};

// Improved version of the SearchAndSelect that actually searches
export const DropdownSearch: FC<Props> = ({ items, renderItem, onSelect, placeholder }) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [showResults, setShowResults] = useState(false);
  const [displayText, setDisplayText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Item[]>(items);

  const handleChange = useCallback(
    (val: string) => {
      const valsLowerCase = val.toLowerCase().split(' ');
      const filteredVariants = items?.filter((i) =>
        valsLowerCase.every((v) => i.value.toLowerCase().includes(v)),
      );

      setSearchResults(filteredVariants);
    },
    [items],
  );
  const resultContainer = useRef<HTMLLIElement>(null);

  const handleSelection = (selectedItem?: Item) => {
    if (selectedItem) {
      setDisplayText(selectedItem.value);
    } else {
      setDisplayText('');
    }
    onSelect(selectedItem || null);
    resetSearchComplete();
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    let nextIndexCount = 0;
    const nativeEvent = Object.values(e.detail)[1];
    const identifiedKey = e.key === 'Unidentified' ? nativeEvent.key : e.key;

    if (identifiedKey === 'ArrowDown') {
      nextIndexCount = (focusedIndex + 1) % items.length;
    }

    if (identifiedKey === 'ArrowUp') {
      nextIndexCount = (focusedIndex + items.length - 1) % items.length;
    }

    if (identifiedKey === 'Escape') {
      resetSearchComplete();
      setDisplayText('');
    }

    if (identifiedKey === 'Enter') {
      e.preventDefault();
      handleSelection(items[focusedIndex]);
    }

    setFocusedIndex(nextIndexCount);
  };

  useEffect(() => {
    if (!resultContainer.current?.scrollIntoView) return;

    resultContainer.current.scrollIntoView({
      block: 'center',
    });
  }, [focusedIndex]);

  return (
    <div
      style={{ position: 'relative' }}
      tabIndex={1}
      onBlur={() => {
        setShowResults(false);
      }}
      onKeyDown={handleKeyDown}
    >
      <SearchField
        icon="search"
        placeholder={placeholder}
        size="small"
        value={displayText}
        onClear={() => {
          handleSelection();
        }}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setDisplayText(e.target.value);
          handleChange(e.target.value);
        }}
        onClick={(e) => {
          setShowResults(true);
          if ((e.target as HTMLInputElement).value === '') {
            setDisplayText('');
          }
        }}
      />
      {showResults && (
        <SearchResultWrapper>
          {searchResults.map((i, index) => (
            <SearchResult
              key={index}
              onMouseDown={() => {
                handleSelection(i);
              }}
              ref={index === focusedIndex ? resultContainer : null}
              style={{
                backgroundColor: index === focusedIndex ? 'rgba(0, 0, 0, 0.1)' : undefined,
              }}
              data-testid="dropdown-search-result"
            >
              {renderItem(i)}
            </SearchResult>
          ))}
        </SearchResultWrapper>
      )}
    </div>
  );
};
